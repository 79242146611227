body {
  font-family: 'Nunito Sans', sans-serif;
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  font-size: 100%;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  /* background: pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.wrapper:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #000 url('../src/images/tile.png') repeat center center;
  background-size: 382px 382px;
  z-index: -1;
  opacity: 0.35;
}

.App {
  text-align: center;
  /* width: 40vw; */
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  /* margin-top: -140px; */
  background: black;
  padding: 6rem 4rem 5.5rem 4rem;
  /* border: 4px double rgb(109, 84, 8); */
  /* border: 1px solid rgb(197, 148, 0); */
  outline: 42px solid black;
  /* box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75); */
  border-radius: 1px;
  box-shadow: 0 0 600px 10px rgba(197, 148, 0, 0.5);
}


@media only screen and (max-width: 600px) {
  .App {
    margin-top: -180px;
    outline: 22px solid black;
    padding: 6rem 3rem 5.5rem 3rem;
  }
}

.logo {
  /* transform: scale(0.65); */
  width: 14vw;
  height: initial;
}

@media only screen and (max-width: 600px) {
  .logo {
    /* transform: scale(0.65); */
    width: 40vw;
    height: initial;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

span {
  color: #FFBE00;
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 4rem 0 1rem 0;
}

p {
  margin-top: 0;
}

a {
  color: #ccc;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
}

a:hover {
  color: #FFBE00;
}

/* .tile {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 160px;
  background: url('../src/images/tile.png') repeat 10% 10%;
  background-size: 420px 420px;
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  .tile {
    background-size: 320px 320px;
    height: 552px;
  }
} */
